import React from 'react'
import Page from '../../components/Page/Page'
import Text from '../../components/Text/Text'
import { Column, H1, P } from '../../utils/styles'
import styled from 'styled-components'
import CardGroup from '../../components/Card/CardGroup'
import { servicesData } from '../../consts/cardData'

const ServiceCards = styled(CardGroup)`
	background-color: #fafaff;
	padding: 2rem;
	left: 0;
`

const ColumnWrapper = styled.div`
	display: flex;
`

export default function Loxone() {
	return (
		<Page title='Loxone Smart Home'>
			<P center><Text>Includes all essential elements of a smart home. Add advanced modules for a more connected home. Push the limits of what you thought your home could do.</Text></P>
			<ColumnWrapper>
				<Column>
					<ServiceCards
						chunk={4}
						alignItems='left'
						showLinks={false}
						data={servicesData}
						imageType='banner'
						learnMore={false}
					/>
				</Column>
			</ColumnWrapper>
		</Page>
	)
}
