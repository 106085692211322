import React from 'react'
import Page from '../../components/Page/Page'
import Biography from '../../components/Biography/Biography'
import CallToAction from '../../components/CallToAction/CallToAction'
import { useGlobalData } from '../../utils/useGlobalData'
import CardGroup from '../../components/Card/CardGroup'
import styled from 'styled-components'
import { primaryData } from '../../consts/cardData'

const ServiceCards = styled(CardGroup)`
	background-color: #fafaff;
	padding: 2rem;
	/* padding: 6rem 2rem 2rem 12rem; */
`

export default function Home() {

	const { currentDevice } = useGlobalData()

	const description1 = `AutomationSD is a team of smart home specialists`
	const description2 = ` based in San Diego, CA.`

	return (
		<Page>
			{/* <Carousel
				buttonLabel={'Contact Us'}
				buttonLink={'/contact'}
			/> */}
			<CallToAction
				subTitle= {`${description1} ${description2}`}
				title='The Future of Living'
				linkTo='/contact'
				linkLabel='Contact Us'
			/>
				<ServiceCards
				chunk={3}
				alignItems='left'
				data={primaryData}
				imageType='banner'
				showLinks={true}
			/>
			<Biography videoSize={'small'} currentDevice={currentDevice} /> 
			{/* <FactBanner data={factBannerData}/> */}
		</Page>
	)
}
