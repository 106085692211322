import React from 'react'
import styled from 'styled-components'

const Span = styled.span`
	font-size: ${props => props.size || '1.125rem'};
`

export default function Text({
	children,
	className,
	size
}) {
	return (
		<Span className={className} size={size}>
			{children}
		</Span>
	)
}
