import React from 'react'
import styled from 'styled-components'
import logo from '../../assets/logo/logo.png'
import logoA from '../../assets/logo/logo-a.png'

const Img = styled.img`
	height: 2.75rem;
	position: relative;
	top: -.25rem;
`
export default function Logo({
	className,
	letterOnly
}) {
	return (
		<Img className={className} src={letterOnly ? logoA : logo} alt='logo' />
	)
}
