const groupByChunk = (data, chunk) => {
	const sortedData = [] 
	let i
	for (i=0; i<data.length; i+=chunk) {
			sortedData[sortedData.length] = data.slice(i,i+chunk);
	}
	return sortedData
}

export {
	groupByChunk
}