const navMenuData = [
	// {
	// 	label: 'Blog',
	// 	to: '/blog'
	// },
	{
		label: 'Loxone',
		to: '/loxone'
	},
	{
		label: 'Partner Services',
		to: '/partnerServices'
	},
	{
		label: 'LiteTouch Upgrade',
		to: '/litetouch'
	},
	{
		label: 'Contact',
		to: '/contact'
	},
]

export default navMenuData