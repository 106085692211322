import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { H1, Spacer, Column } from '../../utils/styles'
import Checklist from '../Checklist/Checklist'
import YouTube from 'react-youtube';
import Text from '../Text/Text'
import theme from '../../utils/theme'

const Wrapper = styled.div`
	padding: 0 2rem;
	display: flex;
	flex-direction: ${props => props.collapse ? 'column' : 'row'};
	margin-bottom: 4rem;
`

const SectionTitle = styled(Text)`
	font-weight: 700;
	margin-bottom: 0.5rem;
`

const YouTubeVideo = styled(YouTube)`
	border: 3px solid ${theme.blue};
	flex: 1;
`

const VideoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`

const VideoSubtitle = styled.div`
	font-size: 0.875rem;
`

export default function Biography({
	currentDevice,
	videoSize
}) {

	const collapse = !currentDevice.includes('full', 'desktop') 

	const opts = {
		width: collapse ? '320' : '640',
		height: collapse ? '180' : '360',
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			autoplay: 0,
		},
	};

	const onReady = (e) => e.target.pauseVideo();

	return (
		<Wrapper collapse={collapse}>
			<Column>
				<H1>About AutomationSD</H1>
				{collapse && <YouTubeVideo videoId="fN9_wpqmqIM" opts={opts} onReady={onReady} />}
				<p>
					<Text>
						Our philosophy is that each installation is the artistic result of our ongoing client collaborations.
					</Text>
				</p>
				<p>
					<Text>
						We design and install smart systems for residential homes, apartments, condos, commercial buildings, and specialty vehicles (yachts, RVs). 
					</Text>
				</p>
				<p>
					<Text>
						Our mission is to take you beyond simple light control. We want your home to respond directly to you when you’re home or away, and always keep you updated.
					</Text>
				</p>
				<Spacer invisible />
				<SectionTitle>The Process:</SectionTitle>
				<Checklist items ={[
				'Assess existing wiring, lighting fixtures, and network capabilities',
				'Discuss wishlist and phases of installation',
				'Install hardware, software, and mobile phone control'
				]} />
				<Spacer invisible />
			</Column>
			<Spacer vertical width={'3rem'} />
			<Column>
				{!collapse && 
				<VideoContainer>
				<YouTubeVideo videoId="fN9_wpqmqIM" opts={opts} onReady={onReady} />
				<VideoSubtitle><Link to='/contact'>Contact us</Link> to schedule a virtual live tour with our friends at Loxone!</VideoSubtitle>	
				</VideoContainer>
				}
			</Column>
		</Wrapper>
	)
}
