import styled from "styled-components"
import theme from "./theme"
import Logo from '../components/Logo/Logo'

const Flex = styled.div`
	color: ${theme.darkBlue};
	display: flex;
	flex-direction: ${props => props.column ? 'column' : 'row'};
	align-items: ${props => props.alignItems || 'center'};
	justify-content: ${props => props.justifyContent || 'center'};
`

const H1 = styled.h1`
	font-size: 3rem;
	font-family: 'Montserrat';
	font-weight: 300;
`

const H3 = styled.h3`
	font-size: 2rem;
	font-weight: 700;
	margin: 0.25rem;
	line-height: 1.25rem;
	text-align: ${props => props.center ? 'center' : 'left'};
`

const P = styled.p`
	padding: 0.5rem 0;
	text-align: ${props => props.center ? 'center' : 'left'};
`

const Spacer = styled.div`
	width: ${props => props.width || (props.vertical ? '1px' : '100%')};
	height: ${props => props.height || (props.vertical ? '100%' : '1px')};
	margin: 1rem 0;

	${props => props.vertical && `
		margin: 0 1rem;
	`}

	${props => !props.invisible && `
		background-color: #ccc;
	`}
`

const defaultFont = `
	font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	font-size: 1.125rem;
`

const Column = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	text-align: left;
`

const MainSection = styled(Flex)`
	margin-bottom: 3rem;
	flex-direction: ${props => props.smallScreen ? 'column' : 'row'};
`

const MainImage = styled.img`
	width: 25rem;
	flex: 1;
`

const MainP = styled(P)`
	flex: 1;
	padding: 2rem;
`

const WhiteLogo = styled(Logo)`
	filter: brightness(0) invert(1);
`

export {
	Flex,
	H1,
	H3,
	P,
	Spacer,
	defaultFont,
	Column,
	MainSection,
	MainImage,
	MainP,
	WhiteLogo
}