import React, { useState } from 'react'
import styled from 'styled-components'
import { device } from '../../utils/device'
import Logo from '../Logo/Logo'
import Button from '../Button/Button';
import theme from '../../utils/theme';
import { useGlobalData } from '../../utils/useGlobalData';
import { Flex, WhiteLogo } from '../../utils/styles';
import { Link } from 'react-router-dom';
import navMenuData from '../../consts/navMenuData';
import uuidv4 from 'uuid/v4'

export const headerHeight = '4rem'

const Wrapper = styled.header`
	position: relative;
	height: ${headerHeight};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 0 1rem;

	@media ${device.tablet} {
		margin: 0;
	}


	@media ${device.desktop} {
		margin: 0 1rem;
	}	
`

const NavMenu = styled.ul`
	display: flex;
	flex-direction: row;
	list-style-type: none; 
  padding: 0;
	margin: 0;
	color: ${theme.blue};
`

const NavItem = styled(Link)`
	padding: 0 0 0 2rem;
	white-space: nowrap;
	text-decoration: none;
	color: ${theme.darkBlue};
	position: relative;
	transition: 250ms all ease;
	:hover {
		transform: scale(1.05);
		color: ${theme.blue};
	}
`

const MobileNavMenu = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #fff;
	right: 0.5rem;
	top: 4rem;
	background-color: ${theme.blue};
	width: 15rem;
	padding: 0.5rem 0;
	box-shadow: 0 2px 2px 3px rgba(0,0,0,0.5);

`

const MobileNavItem = styled(Link)`
	padding: 0.75rem 0;
	width: 100%;
	white-space: nowrap;
	text-decoration: none;
	box-shadow: 0px 2px 3px 1px rgba(255,255,255,0.1);
	color: ${theme.white};
	position: relative;
	transition: 250ms all ease;
	:hover {
		transform: scale(1.05);
	}
`

const horizontalNavMenu = 
	<NavMenu>
		{navMenuData.map(item => 
			<NavItem key={uuidv4()} to={item.to}>{item.label}</NavItem>
		)}
	</NavMenu>

const MobileMenuWhiteLogo = styled(WhiteLogo)`
	margin-bottom: 0rem;
	
`

const Hamburger = styled.div`
	font-size: 1.75rem;
	transform: ${props => props.isShowing ? 'rotate(90deg)' : 'rotate(0deg)'};
	transition: all .35s ease-in-out;
	cursor: pointer;

`

const MobileNavMenuBackdrop = styled.div`
	top: 0;
	right: 0rem;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,0.50);
	z-index: 10;
	${props => props.isShowing && `
		position: fixed;
		touch-action: none;
	`}
`

const MobileNavClose = styled.button`
	border: none;
	background: none;
	outline: none;
	position: absolute;
	color: ${theme.white};
	top: 0.5rem;
	right: 0.5rem;
	cursor: pointer;
	:hover {
		transform: scale(1.25);
	}
`

export default function Header() {
	const { currentDevice } = useGlobalData()

	const [mobileNavIsShowing, setMobileNavIsShowing] = useState(false)
	const toggleMobileNavMenu = () => setMobileNavIsShowing(prevState => !prevState)

	const small = ['small', 'mobile'].includes(currentDevice)

	const menu = small ?	null : horizontalNavMenu

	return (
		<Wrapper>
			{mobileNavIsShowing && 
				<MobileNavMenuBackdrop isShowing={mobileNavIsShowing} onClick={toggleMobileNavMenu}>
					<MobileNavMenu onClick={toggleMobileNavMenu}>
						<MobileNavItem to='/'><MobileMenuWhiteLogo letterOnly /></MobileNavItem>
						{navMenuData.map(item => 
							<MobileNavItem key={uuidv4()} to={item.to} onClick={toggleMobileNavMenu}>{item.label}</MobileNavItem>
						)}
						<MobileNavClose onClick={toggleMobileNavMenu}>x</MobileNavClose>
					</MobileNavMenu>
				</MobileNavMenuBackdrop>
			}
			<Button linkTo='/' image={<Logo />} bordersize={0} />
			<Flex>
				{small && <Hamburger isShowing={mobileNavIsShowing} onClick={toggleMobileNavMenu}>☰</Hamburger>}
				{menu}
			</Flex>
		</Wrapper>
	)
}
