import codeImg from '../assets/carousel/code.png'
import livingRoomImg from '../assets/carousel/living-room.png'
import newConstructionImg from '../assets/carousel/led-dining-room.jpg'
import motionSensorAir from '../assets/products/motion-sensor-air.png'
import loxoneIntercom from '../assets/products/PH_Loxone-Intercom-XL.png'
import frontYard from '../assets/carousel/front-yard.png'
import loxoneMusicServer from '../assets/products/loxone-music-server.jpg'
import backyardNight from '../assets/carousel/backyard-night.png'
import shadingRemoteAir from '../assets/products/shading_remote_air.jpg'
import waterSensor from '../assets/products/water-sensor.png'
import fountain from '../assets/products/fountain.jpg'
import houseGate from '../assets/products/house-gate.png'
import energyMonitor from '../assets/products/loxone-phone-energy-monitor.png'

const primaryData = [
	{
		title: 'Loxone Smart Home',
		description: 'We design and install custom systems for residential and commercial new builds & remodels.',
		image: newConstructionImg,
		linkTo: '/loxone'
	},
	{
		title: 'LiteTouch Upgrade',
		description: 'We use your existing wiring and the same cabinets where LiteTouch used to be.',
		image: livingRoomImg,
		linkTo: '/litetouch'
	},
	{
		title: 'Partner Services',
		description: `We provide consulting & remote programming for Loxone partners, electricians, and their clients.`,
		image: codeImg,
		linkTo: '/partnerServices'
	}
]

const servicesData = [
	{
		title: 'Lighting',
		description: 'Complete control of lights, when they are on, and their color, from your phone app.',
		image: newConstructionImg,
		linkTo: '/services/lighting-and-rgbw',
	},
	{
		title: 'Motion',
		description: `Detect your presence to control lights, fans, HVAC, hot water circulation, and music.`,
		image: motionSensorAir,
		linkTo: '/services/motion'
	},
	{
		title: 'Music Server',
		description: `Stream your music to as many zones as you need, from any source.`,
		image: loxoneMusicServer,
		linkTo: '/services/music-server'
	},
	{
		title: 'Video Intercom',
		description: `Know and record who's at the door and let them in, from wherever you are.`,
		image: loxoneIntercom,
		linkTo: '/services/video-intercom'
	},
	{
		title: 'Leak Detection',
		description: `Be notified when your hoses burst or icemakers spill...it really happens.`,
		image: waterSensor,
		linkTo: '/services/leak-detection'
	},
	{
		title: 'Gate Control',
		description: `If you have a gate, you can control it.`,
		image: houseGate,
		linkTo: '/services/gate-control'
	},
	{
		title: 'Pool & Spa',
		description: `Control your pumps and water temperature.`,
		image: backyardNight,
		linkTo: '/services/pool-and-spa'
	},
	{
		title: 'Sprinklers',
		description: `Monitor soil moisture and water your grass and plants perfectly.`,
		image: frontYard,
		linkTo: '/services/sprinklers'
	},
	{
		title: 'HVAC',
		description: 'Monitor and control your heating and AC for comfort and energy savings.',
		image: livingRoomImg,
		linkTo: '/services/hvac'
	},
	{
		title: 'Automatic Blinds',
		description: `Control your blinds manually, or let the weather decide.`,
		image: shadingRemoteAir,
		linkTo: '/services/automatic-blinds'
	},
	{
		title: 'Fountains',
		description: `Timers to control your fountains to save water and energy.`,
		image: fountain,
		linkTo: '/services/fountains'
	},
	{
		title: 'Energy Monitoring',
		description: `For ultimate savings, monitor every aspect of your energy usage.`,
		image: energyMonitor,
		linkTo: '/services/energy-monitoring'
	}
]

export {
	primaryData,
	servicesData
}