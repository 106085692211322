// https://jsramblings.com/how-to-use-media-queries-with-styled-components/

export const deviceValues = {
	small: 576,
	mobile: 768,
	tablet: 992,
	desktop: 1400
}

export const device = {
  small: `(min-width: ${deviceValues.small}px)`,
  mobile: `(min-width: ${deviceValues.mobile}px)`,
  tablet: `(min-width: ${deviceValues.tablet}px)`,
  desktop: `(min-width: ${deviceValues.desktop}px)`,
};