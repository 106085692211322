import React from "react";
import styled from "styled-components";
import { Flex, WhiteLogo } from "../../utils/styles";
import theme from "../../utils/theme";
import Text from "../Text/Text";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background-color: ${theme.darkGray};
  width: calc(100% - 4rem);
  height: 6rem;
`;

const CopyrightText = styled(Text)`
  color: ${theme.white};
  font-size: 0.875rem;

  a {
    color: ${theme.white};

    :hover {
      color: ${theme.silver};
    }
  }
`;

export default function Footer({ currentDevice }) {
  const d = new Date();
  const year = d.getFullYear();
  return (
    <Wrapper>
      <WhiteLogo letterOnly />
      <Flex column>
        <CopyrightText>
          © {year} AutomationSD, a division of{" "}
          {!["full", "desktop"].includes(currentDevice) ? <br /> : null}
          <a href="https://www.digital-awareness.com">
            Digital Awareness, Inc.
          </a>
        </CopyrightText>
      </Flex>
      <div />
    </Wrapper>
  );
}
