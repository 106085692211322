import { useEffect } from 'react'
import styled from 'styled-components';
import { device } from '../../utils/device'
import { defaultFont, H1 } from '../../utils/styles';
import { useGlobalData } from '../../utils/useGlobalData';
import CallToAction from '../CallToAction/CallToAction';
import Footer from '../Footer/Footer';
import Header, { headerHeight } from '../Header/Header'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	${defaultFont}
	margin: 0 auto 4rem auto;
  text-align: center;
	min-height: calc(100vh - ${headerHeight} - 18rem);
  @media ${device.laptop} { 
		max-width: 991px;
  }

  @media ${device.desktop} {
    max-width: 1400px;
  }
`

const ContentWrapper = styled.div`
	padding: 1rem;
`

const Page = ({children, title}) => {

	const { currentDevice } = useGlobalData()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<>
			<Wrapper>
				<Header />
				{title && <H1>{title}</H1>}
				<ContentWrapper>{children}</ContentWrapper>
			</Wrapper>
			
			<CallToAction
				title='The Future of Living'
				short
				linkTo='/contact'
				linkLabel='Contact Us'
				titleFontSize={['full', 'desktop'].includes(currentDevice) ? '4rem' : currentDevice === 'tablet' ? '3rem' : '1rem'}
			/>
			<Footer currentDevice={currentDevice} />
		</>
	)
}
export default Page
