import React from 'react'
import styled from 'styled-components'
import pattern from '../../assets/pattern-3.png'
import { H1 } from '../../utils/styles'
import theme from '../../utils/theme'
import Button from '../Button/Button'
import Text from '../Text/Text'

const Wrapper = styled.div`
	display: flex;
	flex-direction: ${props => props.short ? 'row' : 'column'};
	align-items: center;
	justify-content: ${props => props.short ? 'space-around' : 'center'};
	color: ${theme.white};
	width: 100%;
	height: ${props => props.short ? '12rem' : '24rem'};
	background: url("${pattern}"), linear-gradient(145deg, #051c50 0%, #0a3393 100%);
	z-index: 2;
	margin: 0 0 0 0;
`

const ContactButton = styled(Button)`
	color: ${theme.white};
	box-shadow: 0 0 0 1pt ${theme.white};
	:hover {
		background-color: rgba(255,255,255,0.75);
		font-weight: 700;
		color: ${theme.blue};
		box-shadow: 0 0 0 0.5pt ${theme.blue};
	}
`

const Header = styled(H1)`
	font-weight: 700;
	margin: 2rem;
	
	${props => props.titleFontSize && `
		font-size: ${props.titleFontSize}
	`}
`


export default function CallToAction({
	subTitle,
	title,
	linkTo,
	linkLabel, 
	short,
	titleFontSize
}) {
	return (
		<Wrapper short={short}>
			{subTitle && <Text>{subTitle}</Text>}
			<Header titleFontSize={titleFontSize}>{title}</Header>
			{linkTo && linkLabel && <ContactButton linkTo={linkTo} label={linkLabel}/>}
		</Wrapper>
	)
}
