const theme = {
	darkBlue: '#01074A',
	blue: '#084597',
	silver: '#A3A4A6',
	lightSilver: '#F3F4F6',
	loxoneGreen: '#68C550',
	white: '#fefefe',
	darkGray: '#1d1d24'
}

export default theme