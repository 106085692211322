import { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
	Redirect
} from "react-router-dom";
import PartnerServices from './pages/PartnerServices/PartnerServices';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home/Home';
import LiteTouchUpgrade from './pages/LiteTouchUpgrade/LiteTouchUpgrade';
import Loxone from './pages/Loxone/Loxone';
import { GlobalDataProvider, useGlobalData } from './utils/useGlobalData';

function Routes() {
  const { setCurrentWidth } = useGlobalData()

	useEffect(() => {
		setCurrentWidth(window.innerWidth)
		window.addEventListener("resize",	() => setCurrentWidth(window.innerWidth));
		return () => {
			window.removeEventListener("resize", () => setCurrentWidth(window.innerWidth));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Switch>
			<Route exact path="/">
				<Home />
			</Route>
			<Route path="/litetouch">
				<LiteTouchUpgrade />
			</Route>
			<Route path="/loxone">
				<Loxone />
			</Route>
			<Route path="/partnerServices">
				<PartnerServices />
			</Route>
			<Route path="/contact">
				<Contact />
			</Route>
			<Redirect to="/" />
		</Switch>
	)
}

function App() {
  return (
		<GlobalDataProvider>
			<Router>
				<div className="App">
					<Routes />
				</div>
			</Router>
		</GlobalDataProvider>
  );
}

export default App;
