import React from 'react'
import styled from 'styled-components'
import Page from '../../components/Page/Page'
import Text from '../../components/Text/Text'
import { H3, P, MainP, Flex, MainSection, MainImage } from '../../utils/styles'
import litetouchToLoxone from '../../assets/products/litetouch-to-loxone.png'
import litetouchLoxonePanelsBeforeAfter from '../../assets/litetouch-loxone-panels-before-and-after.png'
import { Link } from 'react-router-dom'
import { useGlobalData } from '../../utils/useGlobalData'

const PageWrapper = styled(Page)`
	text-align: left;
`

const WideImage = styled.img`
	width: ${props => props.smallScreen ? '90%' : '50%'};
`

const DetailsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 0.5rem;

`

export default function LiteTouchUpgrade() {

	const { currentDevice } = useGlobalData()
	const smallScreen = ['small', 'mobile', 'tablet'].includes(currentDevice)
	
	return (
		<PageWrapper title='LiteTouch Upgrade'>
			{/* //	litetouch-to-loxone.png */}
			<MainSection smallScreen={smallScreen}>
				<MainImage src={litetouchToLoxone} />
					<MainP>
					<H3><Text>LiteTouch custom lighting systems are no longer supported by Savant.</Text></H3>
					<P><Text>LiteTouch was a state-of-the-art lighting system in the last decade of the 20th century. The system was featured in many future oriented and architecturally significant custom homes. Unfortunately the company that purchased LiteTouch has ended all support. When your LiteTouch switch pads break or your lighting modules stop functioning there are few options available to repair or replace your system.</Text></P>
					<P><Text> A Loxone Smart Home system is the answer to LiteTouch replacement.</Text></P>
					<Link to='/contact'>Contact Us to discuss an upgrade</Link>
				</MainP>
			</MainSection>
			<Flex column={smallScreen}>
				<DetailsWrapper>
					<H3><Text>Loxone Smart Home Automation is a proven LiteTouch replacement</Text></H3>
					<P><Text>When your LiteTouch system is no longer working properly or needs repair, a Loxone system is the perfect choice. We can use your existing wiring and often can use the same equipment panels where LiteTouch modules were installed. We replace your LiteTouch switch pads with the Award Winning Loxone Touch Pure Switch.</Text></P>
					<H3><Text>Turn legacy LiteTouch lighting control into a Loxone Smart Home</Text></H3>
					<P><Text>A Loxone Smart Home opens up many possibilities that LiteTouch did not provide. Motion sensors, automatic temperature control, RGBW lighting, sprinkler, pool control and even automatic blinds can be built right in. Any Loxone Touch Pure switch can control any (or even all) lights in your home.</Text></P>
					<H3><Text>Complete control of your home with your Smartphone</Text></H3>
					<P><Text>By replacing your LiteTouch System with Loxone Smart Home automation, you can control and monitor your home with your Smartphone from across the globe. Every switch and device that you operate when at home is available on your Smartphone.</Text></P>
				</DetailsWrapper>
				<WideImage smallScreen={smallScreen} src={litetouchLoxonePanelsBeforeAfter} />
			</Flex>
		</PageWrapper>
	)
}
