import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import theme from '../../utils/theme'
import { defaultFont } from '../../utils/styles'

const Label = ({label}) => <span>{label}</span>

const wrapperCss = props => `
	${defaultFont}
	height: 2.5rem;
	width: ${props.width || '10rem'};
	border-radius: 0.125rem;
	background: none;
	cursor: pointer;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${theme.blue};
	box-shadow: 0 0 0 ${props.bordersize}pt ${props.bordercolor || theme.blue};
`
const Wrapper = styled.button`
	${props => wrapperCss(props)}
`

const LinkWrapper = styled(Link)`
	${props => wrapperCss(props)}
`

export default function Button({
	onClick,
	image,
	label,
	linkTo,
	width,
	bordersize = 1,
	className
}) {

	const LinkComponent = ({linkTo, width, children, bordersize}) => <LinkWrapper className={className} width={width} bordersize={bordersize} to={linkTo}>{children}</LinkWrapper>
	const ButtonComponent = ({onClick, width, children, bordersize}) => <Wrapper className={className} width={width} bordersize={bordersize} onClick={onClick}>{children}</Wrapper>

	if (linkTo) {
		return (
			<LinkComponent className={className} width={width} bordersize={bordersize} linkTo={linkTo}>
				{image}
				<Label label={label}/>
			</LinkComponent>
		)
	}
	return (
		<ButtonComponent className={className} width={width} bordersize={bordersize} onClick={onClick}>
			{image}
			<Label label={label}/>
		</ButtonComponent>
	)
}
