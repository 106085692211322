import React, { useState, useEffect, useContext } from "react";

export const GlobalDataContext = React.createContext();
export const useGlobalData = () => useContext(GlobalDataContext);

export const GlobalDataProvider = ({
  children
}) => {
	
	const [currentWidth, setCurrentWidth] = useState(0)
	const [currentDevice, setCurrentDevice] = useState('small')
	useEffect(() => {
		if (currentWidth > 1200) {
			setCurrentDevice('full')
		} else if (currentWidth > 992) {
			setCurrentDevice('desktop')
		} else if (currentWidth > 768) {
			setCurrentDevice('tablet')
		} else if (currentWidth > 576) {
			setCurrentDevice('mobile')
		} else {
			setCurrentDevice('small')
		}  
	}, [currentWidth])

  return (
    <GlobalDataContext.Provider
      value={{
				currentWidth,
				setCurrentWidth,
				currentDevice
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
};