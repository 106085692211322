import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { device } from '../../utils/device'
import theme from '../../utils/theme'
import Text from '../Text/Text'

const LinkWrapper = styled(Link)`
	text-decoration: none;
	color: ${theme.darkBlue};
`
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: left;
	text-align: left;
	padding: 2rem;
	margin: 1rem 0.5rem;
	border-radius: 0.5rem;
	background-color: ${theme.white};
	color: ${theme.black};
	transition: all 250ms ease;
	cursor: default;
	bottom: 0rem;
	position: relative;
	max-width: ${props => props.size ? '12rem' : '16rem'};
	box-shadow: 0 2pt 2pt 1pt rgba(0,0,0,0.1);

	@media ${device.desktop} { 
		margin: 1rem;
	}
	
	${
		props => props.showLinks && `
		:hover {
			background-color: ${theme.blue};
			color: ${theme.white};
			bottom: 0.25rem;
			box-shadow: 0;
		}
	`}	
`

const BannerImage = styled.img`
	width: 100%;
	height: 8rem;
	border-radius: 0.5rem;
	object-fit: cover;
`

const Title = styled.div`
	font-size: 1.5rem;
	font-weight: 700;
	margin: 1rem 0;
	font-size: ${props => props.type === 'primary' ? '1.375rem' : '1.125rem'};

`

const Description = styled(Text)`
	font-size: ${props => props.type === 'primary' ? '1.125rem' : '0.875rem'};
	height: ${props => props.size === 'small' ? '2rem' : '4rem'};
`

const LearnMoreLink = styled.div`
	margin: 1rem 0 0.5rem 0;
`

const LearnMoreUnderline = styled.div`
	height: 2px;
	width: ${props => props.isHovering ? '5rem' : '3rem'};
	background-color: #000;
	border-radius: 1rem;
	transition: 250ms width ease;
`

export default function Card({
	title,
	description,
	image,
	linkTo = null,
	size,
	learnMore = true,
	showLinks
}) {

	const [isHovering, setIsHovering] = useState(false)

	const card = 
		<Wrapper
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
			size={size}
			showLinks={showLinks}
		>
			<BannerImage src={image} />
			<Title size={size}>{title}</Title>
			<Description size={size}>{description}</Description>
			{learnMore && <>
				<LearnMoreLink to={linkTo}>Learn More</LearnMoreLink>
				<LearnMoreUnderline isHovering={isHovering} />
			</>}
		
		</Wrapper>

	if (showLinks && linkTo) {
		return <LinkWrapper to={linkTo}>{card}</LinkWrapper>
	} else {
		return card
	}
}
