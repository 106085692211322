import React, { useState } from 'react'
import Button from '../../components/Button/Button'
import Page from '../../components/Page/Page'
import TextInput from '../../components/TextField/TextField'
import { sendContactForm } from '../../utils/api'
import styled from 'styled-components'

const FormWrapper = styled.div`
	max-width: 30rem;
	margin: 0 auto;
`

export default function Contact() {

	const [fullname, setFullname] = useState('')
	const [emailAddress, setEmailAddress] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [message, setMessage] = useState('')

	const allFieldsValid = () => {
		if (fullname.length === 0 || emailAddress.length === 0 || phoneNumber.length === 0 || message.length === 0) {
			return false
		}
		return true
	}

	const submitContactForm = async () => {
		if (!allFieldsValid()) {
			alert("Missing Fields!")
			return
		}
		try {
			await sendContactForm(fullname, emailAddress, phoneNumber, message)
			alert('Contact form successfully sent')
			resetForm()
		} catch (err) {
			console.error(err.message)
		} 
	}

	const resetForm = () => {
		setFullname('')
		setEmailAddress('')
		setPhoneNumber('')
		setMessage('')
	}

	return (
		<Page title='Contact Us!'>
			<FormWrapper>
				<TextInput 
					label='Full Name'
					value={fullname}
					onChange={setFullname}
					placeholder={'ex. Jane Smith'}
					required={true}
				/>
				<TextInput 
					label='Email Address'
					value={emailAddress}
					onChange={setEmailAddress}
					placeholder={'ex: jsmith@email.com'}
					required={true}
					type={true}
				/>
				<TextInput 
					label='Phone Number'
					value={phoneNumber}
					onChange={setPhoneNumber}
					placeholder={'ex: 000-333-1234'}
					required={false}
				/>
				<TextInput 
					label='Message'
					value={message}
					onChange={setMessage}
					placeholder={'Enter Message'}
					required={true}
					type='textarea'
				/>
				<Button 
					label='Submit'
					onClick={submitContactForm}
				/>
			</FormWrapper>
		</Page>
	)
}
