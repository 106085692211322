import Page from '../../components/Page/Page'
import Text from '../../components/Text/Text'
import Checklist from '../../components/Checklist/Checklist'
import { P, MainSection, MainImage, MainP } from '../../utils/styles'
import codeImg from '../../assets/carousel/code.png'
import { Link } from 'react-router-dom'
import { useGlobalData } from '../../utils/useGlobalData'

export default function Consulting() {

	const { currentDevice } = useGlobalData()

	const smallScreen = ['small', 'mobile', 'tablet'].includes(currentDevice)

	return (
		<Page title='Partner Services'>
			<MainSection smallScreen={smallScreen}>
				<MainImage src={codeImg} />
					<MainP>
						<b>We facilitate a smooth experience for new or future Loxone Partners.</b>
						<P>
							<Text>
								We have experience helping new and potential Loxone partners start and grow their business. From component selection and wiring to customer relations and how to bill — we can add value to the services you offer while saving hours of trial and error. 
							</Text>
						</P>
						<P>
							<Text>
							We help electricians install Loxone systems by providing remote design, configuration, and programming support.
							</Text>
						</P>
					<Link to='/contact'>Contact Us for More Info</Link>
				</MainP>
			</MainSection>
			<P>
				<Text>
					Our practice and experience can help streamline your installation.  
					Each system requires a customized selection of modules and software components. 
					With our virtual consulting, it's just like we're there with you.
				</Text>
			</P>
			<P>
				
			</P>
			<P>
			<Text>Examples of what we do:</Text>
			<br /><br />
				<Checklist items ={[
					'Program new features for existing systems',
					'Collaborate on system design and installation',
					'Provide Loxone Config tutorials and technical support',
					'Provide models for keypad function, scenes, and app interface',
					'Help with wiring layout',
					'Help with ordering Loxone products and other material',
					'Integrate INSTEON and other smart home technologies with your Loxone system'
					// 
					]} />
			</P>
		</Page>
	)
}
