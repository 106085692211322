import React from 'react'
import styled from 'styled-components'
import { groupByChunk } from '../../utils/helpers'
import { useGlobalData } from '../../utils/useGlobalData'
import Card from './Card'
import uuidv4 from 'uuid/v4'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
`

const CardRow = styled.div`
	display: flex;
	flex-direction: row;
`

export default function CardGroup({
	data,
	className,
	alignItems,
	showLinks,
	size,
	imageType,
	type,
	learnMore,
	chunk = 0,
}) {

	const { currentDevice } = useGlobalData()

	const chunkMod = chunk - 3
	const sortedData = ['small', 'mobile'].includes(currentDevice)
		? groupByChunk(data, 1)
		: currentDevice === 'tablet'
			? groupByChunk(data, 2 + chunkMod)
			: groupByChunk(data, 3 + chunkMod)


	return (
		<Wrapper className={className} currentDevice={currentDevice}>
			{sortedData.map(row => 
			<CardRow key={uuidv4()}>
				{row.map((item, i) => 
					<Card
						key={uuidv4()}
						type={type}
						alignItems={alignItems}
						title={item.title}
						description={item.description}
						image={item.image}
						showLinks={showLinks}
						size={size}
						imageType={imageType}
						linkTo={item.linkTo}
						learnMore={learnMore}
					/>
				)}
			</CardRow>
			)}
		</Wrapper>
	)
}
