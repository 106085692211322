import React from 'react'
import styled from 'styled-components'
import { defaultFont } from '../../utils/styles'
import Text from '../Text/Text'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 1rem;
`

const Input = styled.input`
	${defaultFont}
	font-size: 1.125rem;
	padding: 0.25rem 0.5rem;
	margin-top: 0.25rem;
	width: 90%;
	max-width: 16rem;
`

const Textarea = styled.textarea`
	${defaultFont}
	font-size: 1.125rem;
	padding: 0.25rem 0.5rem;
	margin-top: 0.25rem;
	width: 90%;
	max-width: 32rem;
	resize: none;
	height: 16rem;
`

const Asterisk = styled(Text)`
	font-size: 0.875rem;
	margin: 0.25rem;
`
export default function TextInput({
	label,
	value,
	onChange,
	className,
	placeholder,
	required,
	type,
	isEmail
}) {

	const onChangeHandler = e => onChange(e.target.value)

	return (
		<Wrapper className={className}>
			<Text size='0.875rem'>{label}{required && <Asterisk>*</Asterisk>}</Text>
			{
				type === 'textarea' 
					? <Textarea 
							type='text'
							value={value}
							onChange={onChangeHandler}
							placeholder={placeholder}
						/>
					: <Input
							type={type === 'email' ? 'email' : 'text'}
							value={value}
							onChange={onChangeHandler}
							placeholder={placeholder}
						/>
			}
			
		</Wrapper>
	)
}
