import React from 'react'
import { FaCheck } from 'react-icons/fa'
import styled from 'styled-components'
import theme from '../../utils/theme'
import Text from '../Text/Text'
import uuidv4 from 'uuid/v4'

const FeatureItem = styled.div`
	list-style-type: none;
	padding: 0.25rem 0; 
`

const FaCheckIcon = styled(FaCheck)`
	margin-right: 0.5rem;
	color: ${theme.blue};
	font-size: 0.75rem;
`

const Wrapper = styled.ul`
	text-align: left;
	padding: 0;
	margin: 0;
`

export default function Checklist({items}) {
	return (
		<Wrapper items ={items}>
			{items.map(name => 
				<ChecklistItem  key={uuidv4()} name={name} />
			)}
		</Wrapper>	
	)
}

export function ChecklistItem({name}) {
	return (
		<FeatureItem>
			<FaCheckIcon />
			<Text>{name}</Text>
		</FeatureItem>
	)
}


